export default {
  title: "Manage and create Microsite pages",
  message: `These are texts (translations) that are used in each respective Miscrosite product and collection pages.
  When a Miscrosite gets created by adding a link to it in Menu, Collection banner or Story banner, a default translation
  for that page is created, you can then edit it here.`,
  adding_message: `You can add new homepage translations by adding direct links to products (sigle product pages) or
  collections (couple product pages) in Menu content editor.`,
  single_product_title: "Product microsites",
  couple_product_title: "Collection microsites",
  add_type_page: "Add {type} page translations",
  couple_product: "Collection microsite",
  single_product: "Product microsite",
  modal_title: "Select {entity}",

  entity_type: {
    product: "product",
    collection: "collection"
  }
}
