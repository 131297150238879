export default {
  dismiss: "Dismiss",

  quick_link_tooltips: {
    products: "Products - add, edit, and manage your products",
    orders: "Orders - view and manage your orders",
    about_page: "About page - edit your store's about page",
    shipping: "Shipping - configure your shipping options",
    popular_products: "Popular products - update your most popular products",
    style_config: "Style configuration - change your store's styles",
  },

  quick_link_titles: {
    title: "Quick Links",
    products: "Products",
    orders: "Orders",
    about_page: "About Page",
    shipping: "Shipping Methods",
    popular_products: "Popular Products",
    style_config: "Style Configuration",
  }
}
