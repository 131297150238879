export default {
  title: "Rest of configuration",
  message: "Here, you can configure SEO settings of your page as well as integration of Google, Facebook and other analytics tools.",
  seo_title: "SEO Configurations",
  analytics_title: "Website Analytics Tool Configurations",

  values: {
    seo_description: {
      title: "Website SEO description",
      message: "Description used to define your websites content to Google crawlers."
    },
    theme_color: {
      title: "Theme color of the website in HEX code",
      message: "Colors used to set mobile browser navigation bar color."
    },
    seo_keywords: {
      title: "SEO key words",
      message: "Words used for search engines to correctly index your site."
    },
    google_site_verification: {
      title: "Google site verification code",
      message: "Code provided by Google to enable Google Analytics on your website."
    },
    fb_pixel_id: {
      title: "Facebook Pixel ID",
      message: ""
    },
    hotjar_id: {
      title: "Hotjar App ID",
      message: ""
    },
    ga_project_id: {
      title: "Google analytics measurement or tracking ID",
      message: "Here you insert the Tracking or Measurement ID for your store."
    },
    fb_site_verification: {
      title: "Facebook site verification code",
      message: "Code provided by Facebook to verify ownership of your domain from Facebook business manager."
    }
  }
}
