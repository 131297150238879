export default {
  title: "Menu items",
  add_item: "Add menu item",
  section_title: "Menu items and sub items",
  other_settings: "Other settings",
  add_sub_item: "Add sub item",
  menu_item: "menu item",
  menu_subitem: "menu subitem",
  uppercase_option: "Use uppercase letters in menu",
  modal_title: "Add menu item based on products",
  add_menu_item: "Add empty menu item",
  add_menu_item_from_entity: "Add menu item based on product or collection",
  for_language: "for a language"
}
