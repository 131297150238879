export default {
  title: "Payment options configuration",
  message: `You have the option to select, which payment gateway you wish to be used by your users. 
  Each gateway is dependant on other prerequisites.`,
  message_paypal_title: "PayPal payment gateway",
  message_paypal: `If you wish to use PayPal payment gateway, you need to fill out the PayPal
  configuration.`,
  message_shopify_title: "Shopify payment gateway",
  message_shopify: `If you wish to use Shopify payment gateway, you first need to configure Shopify connection in the
  "Shopify connection configuration" tab in the side menu. Furthermore, Shopify payment gateway can only be used with
  products created through Shopify.`,
  paypal_config_title: "PayPal configuration",
  use_paypal: "Use PayPal as my payment gateway",
  use_shopify: "Use Shopify as my payment gateway",
  checking_connection: "Checking your PayPal connection",
  paypal_not_connected: "PayPal is not connected properly, please check that you have the correct Client and Secret ID's.",
  shopify_not_connected: "Connection to your Shopify store could not be established, therefor you are unable to use Shopify as your payment gateway.",
  necessary_paypal_config: `Make sure to enable 'Payouts' and 'Transaction Search' in the settings when creating your PayPal application.
  Without it, you wouldn't be able to use the Ulsemo cross-selling functionality.`,

  other_payment_options: {
    title: "Other payment options",
    message: "Allow your users to choose other means of payment and increase your conversion rate.",
    options: {
      bank_transfer: "Bank transfer",
      cash_on_pickup: "Cash/card on pickup"
    },
    bank_transfer_tooltip: `You first need to fill out your bank details in "Shipping and address configuration" -> "Store address and info"`
  },

  values: {
    client_id: {
      title: "Paypal client ID",
      message: "The Client ID provided by Paypal when creating a new app here:",
      creation_link: "Business Paypal account"
    },
    client_secret: {
      title: "Paypal secret ID",
      message: "The Secret ID provided by Paypal when creating a new app here:",
      creation_link: "Business Paypal account"
    },
    email: {
      title: "Paypal account email",
      message: "This email is used to identify which Paypal account to pay out any amounts from Ulsemo Cross-selling.",
    }
  }
}
