export default {
  title: "Notifications",
  clear_all: "Clear all",

  orders: {
    new_order: "You have a new order, you can view it"
  },

  cross_products: {
    received_payout: "You received a Paypal payout from cross-sold products!",
    refund_payout: "Cross-selling payout refund",
    deleted: "The following cross-sold product has been removed due to their deletion by the owner",
    updated: "The following cross-sold product has been updated by the owner",
    paypal_payouts_disabled: "The PayPal account you connected does not have the 'Payouts' feature enabled, you will not be able to cross-sell your products until you re-enable it."
  }
}
