export default {
  title: "Manage posts on social media channels",
  message: `This module allows you to create social media posts both individually and across all accounts, on all connected social media channels.
  You can also preview the 10 latest posts from any connected channel.`,
  click_to_show_posts: "Preview latest posts by clicking desired profile, page, group, etc.",
  page_or_group_not_found: `The page or group you have selected could not be found, please check your
  Facebook profile and make sure that you have admin permissions.`,
  no_posts_found: "No posts have been found.",
  previewing_posts: "View posts",
  creating_posts: "Create posts",
  select_social_media: "Select platform",
  select_social_channel: "Select channel",
  no_channels_connected: `No channels connected, go to <b>"Manage accounts"</b> section to connect your social media channels.`,
  textarea_message: "What do you want to share?",
  channels_sharing: "Select channels to share on",
  add_post_images: "Add images",
  add_post_video: "Add video",
  add_post_emoji: "Add emoji",
  publish: "Publish",
  link_title: "Link in post",
  create_from_product: "Create post from product or collection",
  choose_produkt_or_collection: "Select product or collection",
  remove_image: "Remove image",
  remove_video: "Remove video",
  upload_video: "Upload video",
  upload_image: "Upload image",
  post_published_message: "Posts successfully uploaded to selected {provider} channels, you can view them in the 'View posts' section.",

  table: {
    created_time: "Created at",
    picture: "Picture",
    attachments: "All attachments",
    text_content: "Text content",
    is_published: "Is published",
    date_published: "Published at",
    name: "Name",
    image: "Image"
  }
}
