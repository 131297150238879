export default {
  title: "Shopify account configuration",
  message: `To connect your existing Shopify account and use its products and order data,
  please fill in the below information. Once connected, you will be able to manage Products, Orders and other, through
  this administration.`,
  button: "Use Shopify data",
  how_to: `If you need help filling out all the below information, checkout our video on how to
  obtain all necessary information for connecting your Shopify account.`,
  show_video: "Show video tutorial",
  hide_video: "Hide video tutorial",
  retest_connection: "Re-test connection",
  incorrect_credentials: `The connection with your Shopify store could not be established, please check that all fields
  are filled out correctly.`,
  incorrect_scopes: `The connection with your Shopify store is now established, however, you need to correctly configure
  the ADMIN API PERMISSIONS on the "private app" page of your Shopify store, as shown in the video tutorial.`,

  values: {
    should_use_shopify_data: {
      title: "Should use Shopify products",
      message: "Will use Shopify products in your store instead of Imported products."
    },
    shop_name: {
      title: "Shop name",
      message: "The name of your Shopify store."
    },
    shopify_store_url: {
      title: "Shopify store URL",
      message: "The URL of your Shopify store."
    },
    storefront_access_token: {
      title: "Storefront access token",
      message: "Token that can be found in the settings of your Shopify store."
    },
    API_key: {
      title: "API key",
      message: "API key can be found in the settings of your Shopify store."
    },
    API_password: {
      title: "API password",
      message: "API password can be found in the settings of your Shopify store."
    },
    shopify_email: {
      title: "Shopify email",
      message: "Your log in email address to your Shopify store"
    },
    shopify_password: {
      title: "Shopify password",
      message: "Your log in possword to your Shopify store"
    },
  }
}
