export default {
  title: "New user registration",
  message: `Welcome to Ulsemo, we are very pleased that you decided to try out our service,
  we hope you like it and in case of any questions or problems, our support team is always here to help you!`,
  create_new_user: "Create account",
  account_created: "Account created",

  inputs: {
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    email_again: "Email again",
    phone: "Phone number",
    password: "Password",
    password_again: "Password again",
    country: "Country of residence",
    language: "Preferred language",
    project_name: "Store name",
    project_domain: "Store domain",
    project_plan: "Plan type"
  },

  password_strengths: {
    weak: "weak",
    normal: "normal",
    strong: "strong"
  },

  messages: {
    emails_unmatched_or_invalid: "Emails either do not match or are not valid.",
    passwords_unmatched: "Passwords do not match",
    password_stength: "Password strength",
    user_created_successfully: `Your account has been successfully created, a verification email has been sent to your email address.
    Please finalize the account setup process by click the link in the verification email (It can take a couple of minutes for the email to arrive).`,
    required_field: "* Required field",
    not_required_field: "Not required field"
  }
}
