export default {
  title: "You have following errors:",
  preview_error: "We have a software bug in the store preview, we are working on it and hope to fix the issue asap. We apologize for the inconvenience.",

  collections: {
    collection: "collection",
    parent_collection: "Parent collection"
  },

  types: {
    missing_name: "Missing {entity} {value}.",
    selected_items: "You need to select at least one {entity}.",
    duplicate_title: "This {entity} name already exists, please change it."
  },

  product: {
    title: `Product "{product_title}" has following errors:`,
    duplicate_variants: "This product has duplicate variants, please change the variants to have unique option values.",
    missing_price: "{entity} '{entity_name}' is missing price.",
    missing_variant: "This product is missing variants, please add some.",
    missing_dimension: "One or more dimensions are missing on product variants.",
    missing_categorization: "Products offered through Ulsemo marketplace or cross store selling must have a category assigned.",
    missing_country: "If you wish to sell products on Ulsemo Marketplace, you must set a store country of origin in section: Store configurations -> Shipping and address configuration -> Store address and info",
    missing_value_for_translation: "The variant price and options need to be filled out for all available languages.",
    missing_purchase_currency: "This product or some of its variants have the 'Purchase price' filled out, however the 'Purchase price currency' is missing.",
    paypal_connection_for_cross_selling: "To enable Ulsemo cross-selling, you first need to connect your Paypal account a fill out the Paypal login email. Go to payment gateway configuration:",
    missing_cross_currency: 'If you want to enable Ulsemo cross-selling for this product, you must select a "Conversion Currency".',
  },

  configuration: {
    empty_shipping_option: "Some of your shipping options have not been completely filled out.",
    duplicate_shipping_option: "You have duplicate shipping options ({order}).",
    missing_shipping_option_translations: "You have to fill out shipping options for all available languages",
    save_products_warning: `Since you have changed your currency or added a new language, the product currencies have also been updated.
    Make sure to save products by switching to "Products" module or save immediately:`
  },

  finance: {
    discount_entities_not_selected: "Please select at least one product/collection, or tick to apply to 'All products'.",
    missing_limit_value: "Please fill out the value of 'Usage limit'.",
    missing_requirements_value: "Please fill out the value of 'Minimal requirements'.",
    missing_value: "Please fill out discount 'Value'.",
    missing_end_date: "Please select 'End date/time'.",
    missing_end_date_before_start: "End date/time can no be before Start date/time.",
    missing_translation_value: "Please fill out discount 'Value' for every available currency.",
    missing_translation_requirement_value: "Please fill out the value of 'Minimal requirements' for every available currency.",
  },

  social: {
    only_video_or_images: "You can add either a video or images to the post, but not both.",
    video_max_size_reached: "{provider} requires that uploaded video must not exceed {size_limit} in size and {duration_limit} in length.",
    empty_post: "You cannot share an empty post.",
    select_channel: "Please select at least one channel to share the post on.",
    instagram_media_upload_limit: "You can only upload one image or video on Instagram channels",
    unsupported_image_format: "{provider} requires that uploaded image must not exceed {size_limit} in size and has to be one of the following formats: {supported_formats}.",
    ig_image_dimensions_dont_comply: "Instagram requires that width of uploaded image must be in range {width} and in size ratio range {ratio}.",
    missing_campaign_name: "Campaign name must be filled out.",
    missing_campaign_objective: "Campaign objective must be selected.",
    missing_campaign_state: "Campaign state must be selected.",
    missing_bid_cap: "Selected FB campaign requires a bid cap value.",
    missing_ad_name: "The ad name must be filled out.",
    missing_media: "You have to upload at least one image or video.",
    missing_ad_link: "The display link must be filled out.",
    missing_display_link: "For each uploaded media file, you need to add a display link.",
    ad_account_not_selected: "You need to select at least one ad account.",
    ad_campaign_not_selected: "You need to select at least one campaign.",
    ad_fb_page_not_selected: "You need to select which FB page will be associated with this ad.",
    ad_fb_audience_not_selected: "You need to select at least one audience for each selected ad account."
  },

  new_user: {
    TOO_SHORT: "Invalid phone number"
  },

  uploads: {
    general: "Error during saving of your image, please re-load page and try again or contact support.",
    file_size_limit_exceeded: `Image size limit is 10MB, please upload an image of lower size or try compressing the image <a href="https://tinypng.com/" target="_blank">HERE</a>.`
  }
}
