export default {
  title: "Orders",
  unselect_orders: "Unselect orders",

  close_order: "Close order",
  cancel_order: "Cancel order",
  reopen_order: "Re-open order",
  fulfill_order: "Fulfill order",
  edit_order: "Edit order",
  refund_order: "Refund order",
  shipping_and_label: "Pay for and create shipping label",
  update_tracking_id: "Update order tracking ID",
  no_new_orders: "No new orders.",

  close_orders: "Close orders",
  cancel_orders: "Cancel orders",
  reopen_orders: "Re-open orders",
  fulfill_orders: "Fulfill orders",
  edit_orders: "Edit orders",
  refund_orders: "Refund orders or mark as refunded",
  shipping_and_labels: "Pay for and create shipping labels",
  update_tracking_ids: "Update order tracking IDs",
  mark_as_paid: "Mark as paid",

  orders_selected: "orders selected",
  order_origin: "Order origin",
  other_options_info: "Info about other Shopify order actions",
  imported_product_orders: "Imported product orders",
  shopify_product_orders: "Shopify product orders",
  something_went_wrong: "Something went wrong when updating orders. Please try again.",
  other_options_info_title: "Other Shopify order actions",
  view_order_details: "View order details",
  tracking_number: "Tracking number",
  other_options_info_message: `Unfortunatelly, we have not been able to implement all order actions
  from Shopify yet. <br/>We are working as fast as we can to get you all the features and possibilities that Shopify providers.<br/>
  <b>For further order actions, please visit your Shopify orders page.</b><br/><br/>Thank you for your patience!`,
  can_not_be_fulfilled_is_refunded: `One or more selected orders have already been refunded
  and can not be fulfilled, you can however close or cancel them.`,
  titleKey: "Title",
  variant: "Variant",
  latest_orders: "Latest orders",
  total_orders: "Total orders",
  orders_since_beginning: "Total order count since website creation",
  add_note: "Add a new note",
  remove_note: "Remove note",
  notes_title: "Order notes",
  search_label: "Search through orders",
  search_message: "Search for any value found on an order or product. Separate individual values with a comma.",
  view_to_all: "Back to all orders",
  file_download: "Download orders",
  only_paypal: "This function can only be applied to orders payed through Paypal.",
  only_for_ulsemo: "This function can not be applied to orders payed through Shopify gateway.",
  only_for_shopify: "This function can only be applied to orders payed through Shopify gateway.",
  payment_method: "Payment method",
  create_zasilkovna_packet: "Create new Zásilkovna packet.",
  selected_orders_not_zasilkovna: "Not all selected orders are shipped via Zásilkovna.",
  zasilkovna_packet_created: "Zásilkovna packet for this order has already been created.",

  table: {
    origin: "Origin",
    order_number: "Order n.",
    order_id: "Order id",
    status: "Order status",
    financial_status: "Payment status",
    fulfillment_status: "Fulfillment status",
    subtotal_price: "Subtotal price",
    total_line_items_price: "Total product price",
    total_product_discount: "Total discounts code price",
    total_discounts: "Total discounts",
    total_shipping_discounts: "Total shipping discounts",
    total_price: "Total price",
    total_tax: "Total tax",
    total_shipping_price: "Total shipping price",
    currency: "Currency",
    created_at: "Date created",
    contact_email: "User email",
    products: "Products",
    estimated_profit: "Estimated profit",
    incl_tax: "incl. tax",
    discounts: "Discount codes",
    excl_tax: "excl. tax",
    shipping_method: "Shipping method",
    cross_revenue_reduction: "Cross-sold products profit reduction",
    cross_order: "This is an order with cross-sold products",

    statuses: {
      cancel: "cancelled",
      cancelled: "cancelled",
      close: "closed",
      closed: "closed",
      open: "open",
      opened: "open",
      fulfill: "fulfilled",
      fulfilled: "fulfilled",
      ship: "shipped",
      shiped: "shipped",
      edit: "edited",
      edited: "edited",
      shipped: "shipped",
      unshipped: "unshipped",
      restocked: "restocked",
      authorized: "authorized",
      pending: "pending",
      paid: "paid",
      refunded: "refunded",
      voided: "voided",
      null: "unfulfilled",
      undefined: "unknown",
      zasilkovna: "Submit to Zásilkovna"
    }
  },

  payment_methods: {
    paypal: "Paypal",
    bank_transfer: "Bank transfer",
    on_pickup: "Cash/card on pickup"
  },

  discount: {
    code: "Code name",
    amount: "Amount/Percentage"
  },

  order_detail: {
    title: "Order detail",
    order_not_found: "Order not found",
    info_title: "Info",
    address_title: "Billing and shipping",
    shipping_method: "Shipping method",
    shipping_method_name: "Name",
    destination_country: "Destination country",
    destination_state: "Destination state",
    base_price: "Base price",
    final_price: "Final price",
    is_custom: "Is custom method",
    product_picture: "Picture",
    pruduct_name: "Name",
    pruduct_variants: "Variants",
    quantity: "Quantity",
    price: "Price",
    location: "Location",
    location_id: "Location ID"
  },

  other_method_types: {
    zasilkovna: "Zásilkovna"
  },

  order_edit: {
    title: "Edit/update order details",
    billing_address: "Billing data",
    shipping_address: "Shipping address",
    tracking_numbers: "Tracking numbers",
    can_not_edit_fulfilled: "You can not edit orders which have already been fulfilled."
  },

  shipping_modal: {
    shipping_modal_title: "Order shipping costs payment",
    shipping_modal_message: `To initialize the order fulfillment process, you first need to pay for the
    shipping that user chose and payed for.`,
    payment_success_message: "Shipping successfully payed for.",
    payment_info_message: "Payed for these rates: {rate_ids}",
    paying_for_orders: "Paying for following orders",
    no_unpaid: "No unpaid order shipping.",
    shipping_paid: "Shipping labels have been created and downloaded."
  },

  order_print: {
    number: "Invoice n.{order_number}",
    invoice_title: "Invoice",
    supplier: "Supplier",
    customer: "Customer",
    shipping_address: "Shipping address",
    description: "Description",
    price: "Price",
    discount: "Discount",
    vat: "VAT",
    quantity: "Quantity",
    product_total: "Total",
    shipping_total: "Shipping total",
    subtotal: "Subtotal",
    VAT_total: "VAT total",
    invoice_total: "Invoice total",
    date_of_creation: "Date of creation",
    date_of_paymnet: "Date of payment",
    paid: "Paid",
    businessID: "Business ID",
    vatID: "VAT ID"
  },

  pagination: {
    multiple: "Showing {from} to {to} of {count} total",
    some: "Total: {count}",
    one: "Only one order",
    per_page: "Results per page"
  }
}
