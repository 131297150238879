export default {
  title: "Your main online store configuration",
  message: "Here, you can configure emails associated with your website, as well as domain and email service your email addresses are connected through.",
  domain_error: "Incorrect domain format (eg: myshop.com)",
  test_connection: "Send test email",
  test_connection_tooltip: "Will send a test order confirmation email, with the look of a real order email of how you and your customers will see it, to {email}.",
  test_connection_success: "Test email successfully send, view your inbox of: {email}.",
  test_connection_tooltip_disabled: "Make sure you filled out the order email and password, and have saved all changes in configuration.",

  values: {
    domain: {
      title: "Store domain",
      message: "Set your store's domain, eg: mystore.com. After entering full domain name, we will contact you with further steps to connect your domain."
    },
    email_service: {
      title: "Email provider",
      message: `Here you setup your email service provider, e.g. Gmail, Yahoo, ... . If you can not find your
      email service provider in the list below, please let us know and we will try to add its configuration to our servers.`
    },
    info_email: {
      title: "Info email address",
      message: "Email address where messages from the Contact page will be sent to."
    },
    order_email: {
      title: "Order email address",
      message: `Email address where we will sent informatio about incomming orders, this address will also be used to send order confirmation emails
      to your customers. There for we will need the password to this email, so that we can do so on your behalf.`
    },
    order_email_password: {
      title: "Order email password",
      message: "Password to your order email."
    },
    project_name: {
      title: "Store name",
      message: "Name of your store."
    }
  },

  messages: {
    Gmail: `
      We see you use Gmail as your email provider. You will need to enable less secure apps or set up an app password.
      <a href="https://myaccount.google.com/lesssecureapps" target="_blank"><b>Less secure apps</b></a> nebo 
      <a href="https://security.google.com/settings/security/apppasswords" target="_blank"><b>App passwords</b></a>
      <br/><br/>
      <a href="https://support.google.com/accounts/answer/185833?hl=en" target="_blank">HERE</a> you can find more information about how to set up an app password.
    `,
    Seznam: "If you want to use Seznam email and have 2-step verification turned on, you'll need to turn off 2-step verification to enable password sign-in.",
    Gmail_password: "Use app password for Gmail accounts with 2-step verification enabled. You can find it <a href='https://myaccount.google.com/apppasswords' target='_blank'>HERE</a>.",
  }
}
