export default {
  title: "Section banners",
  section_title: "Banners",
  category: "Category",
  sub_category: "Sub category",
  add_banner: "Add new empty banner",
  add_banner_from_product: "Add new banner based on product",
  description: "Description",
  collection_banner: "Section banner",
  button_text: "Button text",
  modal_title: "Add banner based on product or collection",
  upload_banner: "Upload banner image",
  direct_route: "Direct route",
  label: "No section banners",
  title_input: "Title",
  message: `Collection banners allow you to show a preview of a new collection or
    product, with the ability to share some news or info with the user.`,
  default_message: "Here goes your banner description.",
  default_title: "Here goes the title.",
  demo_message: "Here will show your 'Section banners'."
}
