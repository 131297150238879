export default {
  title: "Upload logo and brand banner",
  icon: "Icon upload",
  brand_banner: "Brand banner upload",
  fav_icon: "Favicon upload",
  preview_color: "Preview on: {color_type}.",
  message: `Your logo will be used in the Menu section of the website and the brand banner
  is shown at the bottom of microsite pages. The banner is used as a link to your online store's homepage.
  Make sure your logo has enough contrast with the heavy background color and that the brand banner has enough contrast with body color.
  The favicon is the small icon shown in the tab of your browser window.`,
  icon_format_message: "Use only images in following format: .png, .jpg/.jpeg, .gif",
  uploading_icon: "Uploading icon",
  uploading_banner: "Uploading banner",
  uploading_favicon: "Uploading favicon",
}
