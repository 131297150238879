export default {
  title: "Manage your connected social media accounts",
  message: "This module allows you to manage connections to profiles, pages, groups and other accounts within selected social networks.",
  connect_page: "Connect pages",
  connect_group: "Connect groups",
  select_entities_to_connect: "Select {source} {entity} to connect",
  entity_list: "Not connected {entity}",
  social_media_platfroms: "Social media platforms",
  connected_entities: "Connected {entity}",
  facebook_entities: "Facebook pages",
  no_entities_found: "No {source} {entity} have been found.",
  no_entities_connected: "No {source} {entity} are connected.",
  load_entities: "Load {entity}",
  connect: "Connect",
  disconnect: "Disconnect",
  switch_account: "Switch account",
  logged_in_as: "Logged in as",
  fb_group_connection_message: `<b>To connect Ulsemo to your Facebook group, you also need to add the Ulsemo app to your group's apps.
  You can watch a short tutorial on how to do it <a href="https://youtu.be/WPlJgOaRjyQ?t=42" target="_blank">HERE</a></b>`,
  connect_google_analytics_view: "New GA view ID",
  google_analytics_view_help: "GA view ID can be found in analytics.google.com -> Admin -> View settings",
  google_signin: "Sign in with Google",

  providers: {
    fb: "Facebook",
    ig: "Instagram",
    google: "Google"
  },
  entity_types: {
    pages: "pages",
    groups: "groups",
    accounts: "accounts",
    ad_accounts: "advertisement accounts",
    ad_campaigns: "advertisement campaigns",
    analytics_views: "analytics views",
  }
}
