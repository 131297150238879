export default {
  title: "Customize store colors",
  change_color: "Change color",
  select_color: "Select color",
  set_to_default: "Set to default color",
  message: `Make sure that you test all custom colors before publishing your website.
  Wrong colors can cause SEO problems due to not having enough contrast between text color and its background color.
  We recommend not using overly vivid colors and making sure there is enough contrast between text and background colors.`,
  palettes_title: "Try out our palettes",
  palettes_message: "We have prepared a couple of modern color palettes that will help you get started faster.",

  style_translations: {
    font_light: {
      title: "Light text color",
      message: `Color used mainly on text that has "Dark background color" as its background color.`
    },
    font_heavy: {
      title: "Dark text color",
      message: `Color used mainly on text that has "Light background color" as its background color.
        Further more its used for little aspects across the site like border colors, icons, etc...`
    },
    background_light: {
      title: "Light background color",
      message: `Color used for example in product detail page, in the Story banner and more.`
    },
    background_heavy: {
      title: "Dark background color",
      message: `Color used for backgrounds of main element in the website like Menu, Preview basket, .`
    },
    body_color: {
      title: "Web background color",
      message: `Color used as the background color of the entire website. Be careful when changing it!`
    },
    item_name_color: {
      title: "Menu item text color",
      message: `Color used on text in the menu, it has "Dark background color" as its background color.`
    }
  }
}
