export default {
  project_access_denied: "You do not have access to this store.",
  wrong_password: "Incorrect password. If you forgot your password, you can reset it byt clicking 'Forgot password'.",
  unknown_project: "You are trying to log into an unknown store.",
  invalid_permissions: "You do not have permissions for this action.",
  session_expired: "Your session expired, try logging in again.",
  email_not_verified: `Your email address has not yet been verified, please go to your email
  inbox, locate and open the verification email and click the verification link. Then try logging in again.`,
  project_limit_reached: "Maximum number of online stores reached.",
  TOO_SHORT: "Phone number is too short.",
  "auth/invalid-email": "Wrongly formatted email address.",
  domain_not_unique: "This domain is already in use by another store, use another domain or contact us.",
  payouts_not_enabled: "Paypal 'Payouts' were not enabled or the changes have not taken effect yet, there for you wont be able to use Ulsemo cross-selling until then."
}
