export default {
  address1: "Street",
  address2: "Street additional",
  city: "City",
  first_name: "First name",
  last_name: "Last name",
  zip: "Zip",
  country: "Country",
  phone: "Phone",
  state: "State",
  company: "Company",
  name: "Name",
  email: "Email",
  street1: "Street",
  street2: "Street additional",
  businessID: "Business ID",
  vatID: "Business VAT ID",
  bank_account: "Bank account number",
  iban: "IBAN",
  swift: "BIC (SWIFT)",
  bank_details: "Bank detail of your store",
  bank_detail_message: "Only needed if you allow your users to pay via bank transfer.",
  vs_format: "The format of the variable (reference) symbol on your invoices will be the same as order numbers, meaning:"
}
