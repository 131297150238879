export default {
  title: "Stock and Inventory",
  message: `This module allows you to view the state of all of your products or services in a struckured list and update quntity and other paramenters.
  Furthermore, you can do an inventory check between your physical stock and the store's stock here as well.`,
  new_inventory_check: "New inventory check",
  cancel_inventory_check: "Cancel inventory check",
  stock_tab: "Stock",
  inventory_tab: "Inventory checks",
  no_inventory_checks: "No inventory checks found",
  conducted_inventory_checks: "Conducted inventory checks",
  evaluate_inventory_check: "Evaluate inventory check",
  surplus_total: "Total surplus",
  shortage_total: "Total shortage",
  total_difference: "Total difference",
  search_label: "Search through product variants",
  inventory_check_from: "Inventory check from:",
  ideal_quantity_explanation: "If the product stock gets below this amount, you will be alerted via email.",
  search_message: "Search for any value found in a product variant. Separate individual values with a comma.",

  stock_table: {
    name: "Name",
    sku: "SKU",
    price: "Price",
    minimum_ideal_quantity: "Ideal quantity",
    actual_stock_quantity: "Actual stock quantity",
    inventory_quantity: "Quantity",
    quantity_difference: "Quantity difference",
    value_difference: "Value difference",
  }
}
