export default {
  title: "Products",
  main_message: `<h3>Product categorization structure is as follows: <i>Parent collection</i> -> <i>Collection</i> -> <i>Product</i>.
    <b>Example: Clothes -> Jackets -> Red bomber jacket.</b>
    <br/>To create a <i>Parent collection</i>, you first must create a <i>Collection</i>.
    <i>Parent collection</i> can not be created without assigning at least one <i>Collection</i> to it.
    <br/><b>A <i>Collection</i> can only be assigned to one <i>Parent collection</i> at any given time.</b></h3>`,
  choose_collection: "Choose a collection:",
  products: "products",
  product: "product",
  product_upper: "Product",
  options: "Variant categories",
  option: "Variant category",
  description: "Description",
  message: "Message",
  price: "Price",
  purchase_price: "Your cost per unit",
  purchase_price_message: "Total price that this product or service has cost you per unit.",
  compare_at_price: "Price before discount",
  weight: "Weight",
  length: "Length",
  height: "Height",
  width: "Width",
  size: "Size",
  quantity: "Total inventory",
  sku: "SKU",
  inventory: "Inventory",
  available: "Available",
  product_name: "Product name",
  value: "Value",
  image: "Image",
  edit_delete_number_of_products: "Edit or Delete {count} variant/s",
  should_product_be_available: "Product is available in store",
  add_product: "Add new product",
  product_unavailable: "Product not available",
  empty_shopify_message: `If you have connected your Shopify account but dont see any products,
  please make sure that all provided information in the Shopify connection configurator is correct.`,
  go_to_shopify_config: "Go to Shopify configurator",
  total_sold: "Total products sold",
  total_sold_message: "Total products sold since website creation",
  total_revenue: "Total revenue",
  total_revenue_message: "Total revenue from products in selected currency since website creation",
  product_dimensions: "Dimensions",
  product_dimensions_message: `These dimensions are used to calculate the best possible shipping rates for your customers.
  If individual product variants differ in dimensions or weight, you can set those dimensions on each individual variant.`,
  product_categorization: "Categorization",
  product_tags: "Tags",
  product_tags_message: `Product tags allows for your products to be easily searchable by customers on
  Ulsemo Marketplace, they also enable similar product matching on your store.`,
  is_physical_product: "Is a physical product",
  variant_dimensions: "Variant dimensions",
  marketplace: "Marketplace placement",
  marketplace_checkbox: "Place on marketplace",
  marketplace_message: "Enables customers to view and buy your product directly from Ulsemo marketplace.",
  selected_language: "Selected language",
  imported_title: "My products",
  shopify_title: "Your Shopify products",
  products_with_errors: "Products with errors:",
  required_fields: "* required fields",
  product_message_info: "Product message is used grab the users attention and spark interest for the product.",
  product_description_info: "Product description is used describe the product in detail and list any important parameters and features.",
  favorite_product_message: "Products marked as 'favorite' have priority when selecting products for product lists in the menu within the collection items.",
  cross: "Ulsemo cross-selling",
  cross_checkbox: "Enable product for cross-selling",
  cross_message: `It allows other merchents within the Ulsemo ecosystem to offer this product under the conditions you set.
  You will then both benefit from the sale of this product through their store.`,
  cross_percentage: "Percentage share of product",

  cross_sale: {
    title: "Ulsemo cross-selling",
    checkbox: "Enable product for cross-selling",
    message: `It allows other merchents within the Ulsemo ecosystem to offer this product under the conditions you set.
    You will then both benefit from the sale of this product through their store.<br>
    The higher the percentage you set, the more insentive other merchents will have to offer your products.`,
    percentage: "Percentage share of product sale",
    percentage_message: "What percentage of the price of the product/variant will belong to the seller who will resell your product.",
    percentage_calculation: "How much will you earn on this product/variant in case of resale:",
    create_button: "Add a product from other Ulsemo merchants",
    search_by_category: "Search by category",
    search_by_parameters: "Search by name and parameters",
    search_by_parameters_placeholder: "🔍   Search by name and parameters",
    found_products: "Found products",
    search_again: "Search again",
    no_products: "No products found",
    country_of_origin: "Country of origin",
    resale_percentage: "Percentage share of product price",
    price: "Price of product or first variant",
    is_cross_sold: "This is a cross-sold product",
    currency_title: "Conversion currency",
    currency_message: "Currency to be used to calculate product price in case of conversion to other currencies.",
    label: "Currency",
    add_cross_product: "Add product to your store",
    preview_cross_product: "Preview product",
    approximate_profit: "Approximate profit on sale of this product or first variant",
    profit_on_product: "How many percent of the product/variant price will you get.",
    already_in_store: "Product already in your store.",
    owner_made_unavailable: "The owner of this product has temporarily suspended it from being sold."
  },

  currency_change: {
    first: "To change the currency of products, go to",
    second: "module and update currency for desired language."
  },

  inputs: {
    weight_unit: "Weight unit",
    distance_unit: "Distance unit",
  },

  add_to: {
    popular: "Add product to Popular products",
    highlighted: "Add product to Highlighted products"
  },

  remove_from: {
    popular: "Remove product from Popular products",
    highlighted: "Remove product from Highlighted products"
  },

  collections: {
    title: "Collections",
    add: "Add new collection",
    edit: "Edit collection",
    name: "Collection name",
    explanation: "Collections are specific groups of products like Jackets in Clothes or Citruses in Fruits",
    collection: "Collection",
    available_collections: "Available collections",
    add_collection_products: "Add existing products to collection",
    add_parent_collection_collections: "Add existing collections to parent",
  },

  parent_collections: {
    title: "Parent collections:",
    add: "Add new parent collection",
    edit: "Edit parent collection",
    name: "Parent collection name",
    explanation: "Parent collection is for example: Clothes to Jackets or Meats to Pork",
    without_parent: "Without parent collection",
    without_collection: "Without collection",
    parent: "Parent",
    no_collection_message: "You must first create a Collection to enable Parent collection creation."
  },

  categories: {
    title: "Product categories selector",
    base_categories: "Base categories",
    open_categories_modal: "Select category",
    change_categories_modal: "Change category",
    shopify_message: "Shopify products can not be offered on Ulsemo Marketplace, please migrate your products to Ulsemo to enable.",
    search: "🔍   Search through categories"
  },

  variants: {
    title: "Variants",
    add_option_placeholder: "Color, Size, Material, etc...",
    message: "Product variants can have any numerical, textual or HEX (CSS color) value.",
    variant: "Variant",
    add_new_variant: "Add new variant",
    edit_selected: "Edit selected",
    done_editing: "Done editing",
    pricing_and_inventory: "Product variants & pricing",
    choose_variant_image: "Choose variant image",
    add_new_variant_option: "Add new category",
    edit_variant_options: "Edit variant categories",
    edit_variant_dimensions: "Edit variant dimensions",
    price_history: "Variant price history",
    price_history_language_data_missing: "Price history for this language is missing.",
    price_history_data_unchanged: "Price has not yet been changed since creation of product/variant."
  },

  images: {
    title: "Images",
    image: "Image",
    preview: "preview",
    edit: "edit",
    add_new_image: "Add new image",
    preview_image: "Preview images",
    edit_image: "Edit image",
    change_image: "Change image",
    delete_image: "Delete image",
    alt_text: "Describe contents of image/s (Alt text)",
    upload_image: "Upload image",
    add_collection_image: "Add collection image",
    upload_images: "Upload image/s",
    edit_images: "Edit image",
    preview_images: "Preview image",
    message: `In this section, you can upload your product images,
    add alt text to them, which helps with SEO of your store,
    as well as edit and re-order by just drag-and-dropping to desired possition.
    If you are planning to add this product to a Microsite page,
    we recommend that the first 3 images are with transparent background (PNG format) images,
    to get the best visual effect.`,
    alt_text_explanation: "Alt (alternative) text describes the contents of the images and is an important part of a product description. <br>If an image can't load for any reason, then alt text is shown instead. Including alt text can boost your website's SEO."
  },

  purchase_price_currency: {
    title: "Costs price currency",
    placeholder: "Select a currency",
    message: `The costs price currency is the currency that was used to incur all costs related to purchase or sale of your product/variant, such as manufacturing, marketing, shipping or any other costs.
    By defining it and assigning each product/variant its value, we can better estimate the net profits of your store.`,
  },

  csv_upload: {
    title: "Upload your products faster by uploading a CSV file!",
    message: "Upload your CSV file below. Make sure that the format of the CSV file is correct, as per our CSV template.",
    template_download: `Please download and follow the data structure as shown in our <a href="/static/others/Ulsemo-product-upload-template.csv" download>CSV template</a> file. Or you can download our empty template without any demo values here <a href="/static/others/Ulsemo-product-upload-empty-template.csv" download>Empty CSV template</a>`,
    tooltip: "Upload products via CSV file",
    table_title: "CSV column explanations",
    column: "Column title",
    explanation: "Explanation",
    drag_and_drop_csv: "Drag and drop CSV file or click here to select",
    fields: {
      parent_collection: "<b>Parent collection</b> - Name of parent collection as you want it to be displayed in store. For example: Clothes",
      collection: "<b>Collection</b> - Name of collection as you want it to be displayed in store. For example: T-shirts",
      product_title: `<b>Product title [LANGUAGE CODE]</b> - Title of the product you are adding. You can also set the title for each available language of your store.
      Just add/update the column name to include the language code used by your store. As an example, take a look at our CSV template, there we add product title for English (EN) and Czech (CZ).<br>
      If no language is added, the title will be applied to all available languages. Available language codes are: EN, DE, ES, CZ, SK, SR, HR, PL, PT, CH, IND, JP, RU, VN`,
      is_product_available: "<b>Is product available</b> - This column sets the availability of the product in your store. Basically says if users can see it or not. Acceptable values are: TRUE or FALSE",
      tags: "<b>Tags</b> - Tags are used to better link similar products together and to aid in SEO searches. The values must be separated by a comma, for example: jackets,clothes,leather jackets",
      images: "<b>Images</b> - Image URLs of your products. If multiple URLs are added, they must be separated by a comma, for example: https://my-images.com/my-cool-image-1.png, https://my-images.com/my-cool-image-2.png",
      image_alt_texts: `<b>Image Alt Texts</b> - Alternative texts help your images rank higher in SEO searches. The values must follow the order of the image URLs, so first Alt text will be added to first image URL and so on.
      If you want all images to have the same Alt text, just add one value and we will add it to all.
      <br> The values must be separated by a comma, for example: Jacket with stripes front,Jacket with stripes back, Jacket with stripes side`,
      message: "<b>Message [LANGUAGE CODE]</b> - Message value of the product you are adding. This value can also be styled HTML code, but must not include any script tags. You can also set the message value for each available language of your store. Works the same way as for the Product title.",
      description: "<b>Description [LANGUAGE CODE]</b> - Message value of the product you are adding. This value can also be styled HTML code, but must not include any script tags. You can also set the message value for each available language of your store. Works the same way as for the Product title.",
      categorization: `<b>Categorization</b> - Categories allow you to specify how we should categorize your products on Ulsemo marketplace if you wish to sell there as well. Furthermore, they allow us to better analyze performance of your products across the Ulsemo ecosystem.
      <br> You must use our categorization system, meaning category ids specified by Ulsemo. You can find them in our <a href="/static/others/Ulsemo-categorization.csv" download>Categorization CSV</a>. For example for category Shirts & Tops, the value would be: id5cd8cc58d109d, ide8091d4f4df2f, idac99bd721e6ef`,
      place_on_marketplace: "<b>Place On Marketplace</b> - Enables you to sell your products through Ulsemo marketplace. You need to fill out the product Categories to enable this option. Acceptable values are: TRUE or FALSE",
      cost_price_currency: "<b>Cost Price Currency</b> - Specifies what currency we should use when calculating any and all costs related to your product. Available currencies are: AUD, BRL, CAD, CNY, CZK, DKK, EUR, HKD, HUF, INR, ILS, JPY, MYR, MXN, TWD, NZD, NOK, PHP, PLN, GBP, RUB, SGD, SEK, CHF, THB, USD",
      dimensions: `<b>Dimensions</b> - Dimensions are used to be able to better calculate shipping rates for your users. You can set global dimensions applicable on every product variant, or separately for each variant in Varian Dimensions column.
      <br>Variants need to be set in following format: <b>length, width, height, weight</b>. For each dimension, you need to set a unit of measurement. Available units are, for Size: m, cm, mm, yd, ft, in and for Weight: kg, g, lb, oz.
      <br>The units can NOT be combined, ie. you can NOT write: 6ft3in but write: 6.25ft. Furthermore, you can not interchange the units, if you choose the Size to be 'cm', than all Size dimensions for this product must use this unit.
      <br>Example of a correct measurment would be: 50cm, 50cm, 100cm, 2.5kg`,
      variant_option_names: "<b>Variant Option Names [LANGUAGE CODE]</b> - These names are to describe the categories of product options. For example, for T-shirt is would be Sizes or Color. <br>You can set up to 3 of these options and they have to be separated by a comma, for example: Sizes, Colors",
      variant_option_values: `<b>Variant Option Values [OPTION POSITION] [LANGUAGE CODE]</b> - These are the values for each variant option that you have set in the 'Variant Option Names' column.
      <br> You need to set the 'OPTION POSITION', which represents the order of your variant options as you have set them in the 'Variant Option Names', so for the first variant option you would put in 1, for second 2 and third 3.
      <br> You can again add language specific values by adding the language code. So in the end, the column name would be for example: 'Variant Option Values 1 EN' and the values for each variant under that option name can be: Small, Medium, Large`,
      variant_prices: "<b>Variant Prices [LANGUAGE CODE]</b> - Here, you can set prices for each variant and language (for currency specified under that language). So for example, the column name would be: 'Variant Prices EN' and values would be: 300, 500, 1000",
      variant_prices_before_discount: "<b>Variant Prices Before Discount [LANGUAGE CODE]</b> - Here, you can set prices before discount (for products on sale) for each variant and language (for currency specified under that language). So for example, the column name would be: 'Variant Prices Before Discount EN' and values would be: 500, 700, 1500",
      variant_skus: "<b>Variant SKUs</b> - Here you can set the SKUs for each variant, separated by a comma. For example: A1B2011, A1B2012, A1B2013",
      variant_total_inventory: "<b>Total Variant Inventory</b> - This enables you to set inventory amounts for each variant, separated by a comma. For example: 500, 380, 425",
      variant_purchase_prices: "<b>Variant Purchase Prices</b> - Allow you to set the purchase price for each variant, separated by a comma. For example: 220, 350, 670",
      variant_dimensions: `<b>Variant Dimensions</b> - Just like with the 'Dimensions' column, this allows you to set the dimensions for each variant separately, in case the dimensions differ dramatically.
      <br>The same rules apply as for the 'Dimensions' column, however here, you need to wrap each set of variant dimension values into parenteses, for example: (50cm, 80cm, 5cm, 500g), (60cm, 80cm, 5cm, 700g), (70cm, 80cm, 5cm, 800g)`,
    }
  }
}
