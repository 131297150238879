export default {
  title: "Welcome to your new store!",
  message: "Let's get you started with a quick 30s tour of the store.",
  continue_button: "Start tour",

  labels: {
    store_config: "Base configuration",
    payment_gateway: "Payment options configuration",
    store_address: "Store address"
  },

  tour: {
    products_menu_tour: {
      title: "Products and inventory",
      content: "This section is where you can add and edit all your products and collections."
    },
    store_layout_menu_tour: {
      title: "Store layout and content",
      content: "In this section, you can update the layout and content of your store, like menus, banners, footer, about page, and other content."
    },
    store_config_menu_tour: {
      title: "Store configuration",
      content: `In this section, you can configure the settings of your store, languages, logo, currency, shipping options, payment gateways, and others.<br>
      You can also change store styling here.`
    },
    finance_menu_tour: {
      title: "Store finance",
      content: "Here you can view and manage your store's finances, such as orders, discount codes, and others."
    },
    marketplace_checkbox_tour: {
      title: "Uploading products to Ulsemo marketplace",
      content: `When creating products, don't forget to check the "Allow product on marketplace" box.
      Otherwise, visitors will not be able to find your product on our marketplace.`
    },
    marketplace_categorization_tour: {
      title: "Categorizing products on Ulsemo marketplace",
      content: `The last step to placing your product on our marketplace, is to select the correct category
      that your product falls under. Please choose one of our available category options.`
    },
    marketplace_collections_tour: {
      title: "Collections and Parent collections",
      content: `If you only plan on offering your products through the Ulsemo marketplace, then you do not have to create any collections.
      The products on our marketplace are sorted according to the category in which you place them.`
    },
    store_preview: {
      title: "Store preview",
      content: "To view your store without the need to deploy it to a domain, you can use this button to toggle a store preview anywhere within the administration."
    },
    quick_links_menu_tour: {
      title: "Quick links",
      content: "These are some useful links to help you navigate the store administration faster."
    },
  }
};
