export default {
  en: "English",
  de: "Deutsch",
  es: "Español",
  pt: "Português",
  ch: "中文",
  ind: "हिन्दी",
  jp: "日本人",
  ru: "русский",
  vn: "Tiếng Việt",
  cz: "Čeština",
  sr: "Srpski",
  sk: "Slovenština",
  hr: "Hrvatski",
  pl: "Polskie"
}
