import products from "./products"
import side_menu from "./side_menu"
import errors from "./errors"
import menu_content from "./menu_content"
import footer_content from "./footer_content"
import collection_banners from "./collection_banners"
import special_message_banner from "./special_message_banner"
import popular_products from "./popular_products"
import highlighted_products from "./highlighted_products"
import story_banner from "./story_banner"
import important_message_bar from "./important_message_bar"
import translations_editor from "./translations_editor"
import edit_page_type_translations from "./edit_page_type_translations"
import style_configurator from "./style_configurator"
import splash_image_configurator from "./splash_image_configurator"
import logo_and_brand_configurator from "./logo_and_brand_configurator"
import shopify_configurator from "./shopify_configurator"
import shipping_options from "./shipping_options"
import rest_configuration from "./rest_configuration"
import project_configuration from "./project_configuration"
import payment_gateway_config from "./payment_gateway_config"
import admin_content from "./admin_content"
import about_page_configuration from "./about_page_configuration"
import api_messages from "./api_messages"
import orders from "./orders"
import user from "./user"
import languages from "./languages"
import address from "./address"
import user_store_selection from "./user_store_selection"
import financials_module from "./financials"
import guide from "./guide"
import discounts from "./discounts"
import social_media_accounts from "./social_media_accounts"
import social_media_ads from "./social_media_ads"
import product_inventory from "./product_inventory"
import finance_analytics from "./finance_analytics"
import social_media_posts from "./social_media_posts"
import analytics from "./analytics"
import store_setup from "./store_setup"
import new_user from "./new_user"
import video_tutorial from "./video_tutorial"
import notifications from "./notifications"
import others from "./others"
import loading_messages from "./loading_messages"
import user_guide from "./user_guide"

export default {
  user_guide,
  video_tutorial,
  notifications,
  new_user,
  store_setup,
  products,
  side_menu,
  errors,
  menu_content,
  footer_content,
  social_media_ads,
  collection_banners,
  special_message_banner,
  popular_products,
  product_inventory,
  highlighted_products,
  story_banner,
  important_message_bar,
  translations_editor,
  edit_page_type_translations,
  style_configurator,
  splash_image_configurator,
  finance_analytics,
  logo_and_brand_configurator,
  shopify_configurator,
  shipping_options,
  rest_configuration,
  admin_content,
  about_page_configuration,
  project_configuration,
  payment_gateway_config,
  user_store_selection,
  financials_module,
  api_messages,
  languages,
  social_media_accounts,
  social_media_posts,
  address,
  user,
  analytics,
  orders,
  guide,
  discounts,
  others,
  loading_messages,
  unknown: "Unknown",
  section_description_title: "Description",
  title: "store",
  save: "Save",
  create: "Create",
  cancel: "Cancel",
  close: "Close",
  select: "Select",
  delete: "Delete",
  restore: "Restore",
  edit: "Edit",
  value: "value",
  add: "Add",
  remove: "Remove",
  done: "Done",
  pay: "Pay",
  send: "Send",
  total: "Total",
  data: "data",
  discard_changes: "Discard changes",
  handling_fees: "Handling fees",
  save_changes: "Save changes in <b>{section}</b> section",
  upload: "upload",
  currency_sign: "$",
  name: "Name",
  refresh: "Refresh",
  route: "Route",
  language_active: "Active language",
  language_inactive: "Inactive language. To activate, change any value.",
  drag_and_drop_image: "Drag and drop image or click here to select multiple images.",
  drag_and_drop_video: "Drag and drop video or click here to select.",
  clear_image: "Clear image",
  upload_image: "Add image",
  clear_images: "Clear images",
  upload_images: "Add images",
  button: "button",
  updated_to: "updated to",
  connection_checks: "Connections to other services",
  connected: "connected",
  not_connected: "not connected",
  financials: "Financials",
  comming_soon_message: "Other data and graphs coming soon!",
  expand: "Expand",
  live_preview: "Live preview",
  demo_store_preview: "Demo store preview",
  demo_store_preview_message: "Only store demo preview, not actually functional.",
  available_languages: "Available languages",
  available_languages_message: "Your store languages. To enable more languages, go to 'Translations & localization' module.",
  no_permissions_message: "You dont have access to this page.",
  default_payment_success_message: "Payment was successful.",
  enable_language_message: "Language must first be enabled in the 'Translations & localization' module.",
  missing_language_values: "You have not filled out all necessary product values for this language.",
  show_store_preview: "Show preview",
  hide_store_preview: "Hide preview",
  full_screen_preview: "Show fullscreen",
  mobile_preview: "Show mobile view",
  here: "HERE",
  next: "Next",
  back: "Back",
  continue: "Pokračovat",
  hide: "Hide",
  show: "Show",
  finish: "Finish",
  ulsemo_data: "Ulsemo data",
  shopify_data: "Shopify data",
  select_currency: "Select currency",
  title_translation: "Title",
  active: "Active",
  expired: "Expired",
  actions: "Actions",
  financial_analytics_link: "View financial analytics of your store",
  no_products_or_collections: "You have not created a product or collection yet.",
  choose_product_or_collection: "Choose product or collection",
  preview_store: "Preview store",
  close_preview_store: "Closer store preview",
  unsaved_change_store_preview: "The preview data is out of date. Please save local changes and re-open store preview.",
  global_admin_specific_module: "To use this module, please switch to the global admin application:",
  time: {
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds"
  },
  countdown_clock_title: "Trial runs out in",
  countdown_finished_title: "Your free trial has ended!",
  countdown_finished_message: "Until you have subscribed to Ulsemo services, all your admin functions will be disabled."
}
