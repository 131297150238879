export default {
  title: "Translations & localization",
  message: `When adding a new language, make sure to also update translations of your products/variants, about page, custom shipping options and footer.`,
  translations_key: "Translations key",
  value: "Value",
  deactivate_language: "Deactivate language",
  activate_language: "Activate language",
  activation_message: "The selected language has not yet been activated.",
  global_translations: "Global translations",
  global_translations_tooltip: "Translations for buttons, titles, messages, scattered across entire website.",
  special_page_translations: "Other page translations",
  special_page_translations_tooltip: "Translations for pages: Privacy policy, Return policy, Terms and conditions.",
  remove_language_message: "You need translations for at least one language",
  privacy_policy: "PRIVACY POLICY",
  return_policy: "RETURNS POLICY",
  terms_and_condition: "TERMS & CONDITIONS",
  footer_message: `
  Don't forget to replace the fields marked <b>XXXXXXXX<b/> with actual information.<br/>
  If you want to exclude one of the below pages from your website, leave its content empty and
  remove the link tag from footer (make sure you test the footers after you have edited it).<br/>
  You can jump to footer edit page:`,
  footer_page_link: "HERE",
  currency: "Currency",
  currency_sign: "Currency sign",
  tax_percantage: "Country tax percentage",
  tax_percantage_message: "Tax percentage that will automatically be added to basket price during the user checkout process."
}
