export default {
  title: "Financial overview of your store",
  no_sold_products: "No sold products as of yet.",
  no_viewed_products: "No products have been viewed as of yet.",
  graph_titles: {
    sales_in_days: "Total sales in selected period: {value}{currency_sign}",
    sales_by_product: "Top products by units sold",
    views_by_product: "Top products by views",
    avg_order_value: "Evolution of average order value",
    best_netting_variants: "Most profitable products/variants"
  },
  graph_labels: {
    sales_in_days: "Sales on this day in {currency}",
    net_profit: "Net profit from this product/variant in {currency}",
    avg_order_value: "Value in {currency}",
    order_value_raising: "The avg. order value has a growing trajectory 👍",
    order_value_falling: "The avg. order value has a falling trajectory 👎",
  },
  chart_buttons: {
    thirty_days: "30 days",
    fourteen_days: "14 days",
    seven_days: "7 days",
    top_ten_products: "Top 10",
    top_five_products: "Top 5",
    store_products: "Store views",
    marketplace_products: "Marketplace views",
    both_platforms: "All views"
  }
}
