export default {
  title: "About page configuration",
  top_banner_image: "Top banner image",
  bottom_banner_image: "Bottom banner image",
  enable_about_page: "Enable about page",
  tiles: "Tiles",
  banners: "Banners",
  bottom_paragraphs: "Bottom paragraphs",
  paragraph_title: "Title",
  delete_tiles: "Delete tile pair",
  add_tiles: "Add tile pair",
  top_paragraph: "Top paragraph",
  texts: "Other texts",
  page_title: "Page title",
  page_quote: "Page quote",
  change_banner: "Change banner image",
  delete_banner: "Delete banner",
  about_page: "About page",
  convert_to_image: "Convert banner to image",
  convert_to_text: "Convert banner to text",
  enable_language_message: `To make this language active on your website, first go to Store configuration -> Translations
  and enable selected language by clicking its flag and changing any of its translation keys.`
}
