export default {
  title: "Manage social media ads",
  message: "This module allows you to create, view and manage ads on all connected social media ads accounts.",
  add_ad_images: "Add images",
  add_ad_video: "Add video",
  add_ad_emoji: "Add emoji",
  create_from_product: "Create ad from product",
  headline: "Headline",
  description: "Description",
  display_link: "Display link",
  ad_button: "Call to action button",
  primary_text: "Primary text",
  campaign_errors: "Campaign errors",
  ad_account_errors: "Ad account errors",
  display_link_error_message: "Carousel ads can not have Display links",
  apply_now: "Apply now",
  download: "Download",
  get_quote: "Get quote",
  learn_more: "Learn more",
  sign_up: "Sign up",
  ad_channels_sharing: "Select ad accounts",
  channels_sharing: "Select channels to share through",
  creating_ads: "Create ads",
  previewing_ads: "Views your ads",
  ad_objective: "Ad objective",
  ad_status: "Initial ad state",
  campaign_objective: "Campaign objective",
  campaign_status: "Initial campaign state",
  campaign_created: "Campaign successfully created.",
  create_new_campaign: "Create a new campaign",
  lifetime_budget: "Lifetime budget",
  daily_budget: "Daily budget",
  campaign_name: "Campaign name",
  fb_currency_message: "In Facebook account currency",
  audience_select: "audience",
  audince_selection_title: "Select audiences for selected campaigns",
  create_ad_title: "Create a new ad",
  start_date: "Start date",
  end_date: "End date",
  ad_name: "Name of the ad",
  ad_published: "{provider} ad under the {name} campaign was successfully published.",
  ad_billing_event: "Billing event",
  no_selection: "None",
  pixel_id: "FB pixel ID",
  bid_cap: "FB ad bid cap",
  promoted_object_event_type: "Promoted event",
  clear_ad: "Clear ad",
  some_campaign_ads_not_creates: `Some of your campaign/ad account ads were not created due to some errors with your data from Facebook's side.
  We kept all the ad data so that you can fix these errors and try publishing your ad again.`,
  facebook_errors: "Errors provided by Facebook",
  no_ads_found: "No ads found.",
  remove_slide: "Delete slide",
  add_channels_message: "You need to first add Facebook ad channels here",

  objectives: {
    APP_INSTALLS: "App installs",
    BRAND_AWARENESS: "Brand awareness",
    CONVERSIONS: "Conversions",
    LINK_CLICKS: "Link clicks",
    LOCAL_AWARENESS: "Local awareness",
    MESSAGES: "Messages",
    OFFER_CLAIMS: "Offer claims",
    PAGE_LIKES: "Page likes",
    POST_ENGAGEMENT: "Post engagement",
    REACH: "Reach",
    STORE_VISITS: "Store visits",
    VIDEO_VIEWS: "Video views",
    IMPRESSIONS: "Impressions"
  },
  ad_statuses: {
    ACTIVE: "Active",
    PAUSED: "Paused"
  },
  ad_buttons: {
    APPLY_NOW: "Apply now",
    DOWNLOAD: "Download",
    GET_QUOTE: "Get quote",
    LEARN_MORE: "Learn more",
    SIGN_UP: "Sign up",
    LIKE_PAGE: "Like page",
    SHOP_NOW: "Shop now",
    WATCH_VIDEO: "Watch video",
    BUY_NOW: "Buy now",
    CONTACT_US: "Contact us",
    SEE_MORE: "See more"
  },
  billing_events: {
    IMPRESSIONS: "Impressions",
    LINK_CLICKS: "Link clicks",
    APP_INSTALLS: "App installs",
    PAGE_LIKES: "Page likes",
    POST_ENGAGEMENT: "Post engagement",
    VIDEO_VIEWS: "Video views"
  },
  ad_event_types: {
    ADD_TO_CART: "Add to cart",
    ADD_TO_WISHLIST: "Add to wishlist",
    INITIATED_CHECKOUT: "Initiated checkout",
    ADD_PAYMENT_INFO: "Add payment info",
    PURCHASE: "Purchase",
    SEARCH: "Product search",
    OTHER: "Other"
  }
}
