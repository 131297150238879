export default {
  name: "Story banner",
  section_title: "Create story banner",
  change_banner: "Change banner",
  message: `Story banners allow you to tell a story about your brand, products and philosophy.
  Furthermore, you can add product or collection link buttons, so that upon finishing reading the story,
  users can go exploring.`,
  label: "No story banner created",
  delete_banner: "Delete banner",
  select_links: "Add link",
  add_link_to_entity: "Add link to parent collection, collection or product",
  buttons: "Links",
  images: "Main banner image",
  text: "Text",
  title: "Title",
  demo_message: "Here will show your 'Story banners'."
}
