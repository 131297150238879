export default {
  title: "Select splash image",
  disable_splash: "Disable splash image",
  select_product: "Select product for live preview",
  open_product_selector: "Select Live preview product",
  transparent_image_message: "Please add an image with transparent background to this product for best visual results.",
  message: `Splash images are used as background for main product images on single and couple product pages (product and collection pages).
  Make sure that the product images has anough contrast with the background image so that it is easily visible. Also, it is recomended to 
  add an product image with transparent background to make the product pop and wow the user :)`
}
