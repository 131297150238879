export default {
  name: "Special message banner",
  title: "Special message banner",
  section_title: "Create special message banner",
  change_banner: "Change banner image",
  message: `Special message banners allow you to convert an urgent or important message to your customers.
  They are placed at the very top of the store homepage and are the first thing your visitors will see.`,
  label: "No special message banner created",
  delete_banner: "Delete banner",
  use_laser_graphic: "Use laser graphic under banner.",
  shown_on_single_and_couple: "Show banner on Microsite pages.",
  demo_message: "Here goes your special message banner.",
  first_add_image: "You need to first add banner image above.",
  edit_banner: "Edit banner",
  add_banner: "Add banner",
  add_banner_title: "Add a new banner",
  alt_text_label: "Alt text",
  alt_text: "Describe contents of banner (Alt text)",

  links: {
    title: "Add a link to this banner",
    about_page: "Link to About Us page",
    product_or_collection: "Link to product or collection",
    external: "Link to external url",
    external_placeholder: "https://www.google.com",
    remove: "Remove link on banner",
    selected_link: "Link"
  }
}
