export default {
  title: "Analytics data overview",
  message: `This module allows you to have an overview of you visitors and makes analytics
  based decisions easier, all from one place.`,
  missing_GA_id: "You first need to connect your Google Analytics account in 'Other configurations' of your store.",
  missing_GA_views: "You first need to connect some Google Analytics views.",

  titles: {
    new_vs_returning: "New vs Returning visitors",
    geo_view: "Where are your visitors from",
    sources: "Visitor sources",
    no_data: "There is no data yet.",
    dates: "Select start and end date"
  },

  labels: {
    new_visitor: "New visitor",
    returning_visitor: "Returning visitor",
    top_countries: "Top countries",
    direct: "Direct",
    new: "New",
    returning: "Returning",
    top_sources: "Top sources",
    source: "Source"
  }
}
