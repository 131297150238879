export default {
  title: "Discount Codes",
  message: `Offering discounts can be a powerful marketing strategy for your Ulsemo store.
  To offer discounts, you can create discount codes or set sale prices for individual products.</br>
  Note, that only discounts restricted by basket price or quantity will be  accepted on Ulsemo Marketplace.`,
  code_title: "Discount Codes",
  code_message: "Discount Codes",
  automatic_title: "Automatic discounts",
  automatic_message: "Automatic discounts",
  activity_state: "State",
  number_of_uses: "No. of times used",
  active_from: "Active from",
  selection_title: "Choose products or collections",
  discount_code: "discount code",
  discount_code_error_name: "discount code title",

  codes: {
    code_input_message: "Customers will enter this discount code at checkout.",
    code_placeholder: "e.g. SPRINGSALE",
    types: {
      title: "Types",
      percentage: "Percentage",
      fixed: "Fixed amount"
    },
    value: {
      title: "Value",
      applies_to: "Applies to",
      all: "All products",
      specific_product: "Specific products",
      specific_collection: "Specific collections",
      discount_value: "Discount value",
    },
    requirements: {
      title: "Minimal requirements",
      none: "None",
      minimum_amount: "Minimum purchase amount",
      minimum_items: "Minimum quantity of items"
    },
    limits: {
      title: "Usage limits",
      total_usage: "Limit number of times this discount can be used in total"
    },
    dates: {
      title: "Active dates",
      start: "Start date/time",
      end: "End date/time",
      end_title: "Set end date/time"
    }
  },
}
