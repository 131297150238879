export default {
  title: "Shipping options configurator",
  message_title: "Description",
  message: `In this module, you are able to configure if you want to use custom shipping methods or default method, 
  which uses a special software (still in development) to find and calculate best rates for your customers.
  Futhermore, you can set cart price totals at which you wish to provide discouns on shipping to your custommers,
  as well as store address and information, including your bank account.`,
  shopify_message: `If you wish to setup new Shopify Carrier Services (shipping options for shopify products),
  you need to do that through your Shopify store administration.`,
  remove_language_message: "Removes language translation from all {type}.",
  allowed_countries: "Countries allowed for shipping",
  allowed_countries_for_method: "Countries allowed to ship to using this method",
  maximum_allowed_shipping_discount: "Maximum amount to subtract from shipping cost",
  no_selected_country_message: "If you do not select any countries, all countries will be enabled to the customers.",
  other_shipping_methods: "Other methods",
  zasilkovna_helper: `To retrieve the API parameters below, please register an accout on
  <a href="https://client.packeta.com/{language}/registration/choose-type">Zásilkovna</a> and go to
  <a href="https://client.packeta.com/{language}/support/">User support</a> section.`,

  inputs: {
    title: "Title",
    price: "Price",
    discounted_price: "Discounted price",
    code: "Code",
    minimum_basket_price: "Minimum basket price",
    discount_percentage: "Discount percentage",
    countries: "Countries",
    maximum_shipping_discount: "Max. shipping discount",
    estimated_days: "Estimated number of days",
    use_custom_methods: "Use custom shipping methods",
    enables_on_pickup_payment: "Enables payment on pickup",
    show_in_contact_page: "Show store address info in contact page.",
    enable_zasilkovna: "Enable Zásilkovna",
    api_key: "API Key",
    api_password: "API Password"
  },

  tooltips: {
    use_custom_methods: "Calculated shipping rates are under development, please use custom shipping methods.",
    enables_on_pickup_payment: "Allows users to select payment on pickup method in checkout."
  },

  tabs: {
    custom_methods: "Shipping methods",
    shipping_discounts: "Shipping discounts",
    settings: "Settings",
    address: "Store address and info",
    address_message: "Setup the primary address of your online store.",
    custom_methods_message: `Setting custom methods will disable possibility for customers to get rates calculated.
    They will have to use one of these methods.`,
    shipping_discounts_message: `Here you can set discounts on shipping depending on total amount of basket price.
    For example: Customer has products in cart exceeding $100, than you can set that shipping
    will be free for that customer.`,
    settings_message: `In this module, you can set which countries you want to enable shipping to, maximum amount
    by which you are willing to reduce the shipping cost for your custommers (for example when there is a discount on shipping), and more.`
  }
}
